import { ACTIVITY_TYPES } from 'state-domains/constants';
import { ExportDestination, ExportEncoding, QuotesMode } from 'state-domains/domain/data';

export const i18nExportFormFields: Record<string, any> = {
    project: {
        id: 'data-export-templates.project',
        defaultMessage: 'Project',
    },
    description: {
        id: 'data-export-templates.description',
        defaultMessage: 'Description',
    },
    destination: {
        id: 'data-export-templates.destination',
        defaultMessage: 'Destination',
    },
    depthUnit: {
        id: 'data-export-templates.depthUnit',
        defaultMessage: 'Depth Unit',
    },
    name: {
        id: 'data-export-templates.name',
        defaultMessage: 'Name',
    },
    makeSharedTemplate: {
        id: 'data-export-templates.makeSharedTemplate',
        defaultMessage: 'Make shared template',
    },
    exportOptions: {
        id: 'data-export-templates.exportOptions',
        defaultMessage: 'Export Options',
    },
    m: {
        id: 'data-export-templates.meters',
        defaultMessage: 'm',
    },
    ft: {
        id: 'data-export-templates.feet',
        defaultMessage: 'ft',
    },
    type: {
        id: 'data-export-templates.type',
        defaultMessage: 'Type',
    },
    code: {
        id: 'data-export-templates.code',
        defaultMessage: 'Code',
    },
    encoding: {
        id: 'data-export-templates.encoding',
        defaultMessage: 'File encoding',
    },
    quoting: {
        id: 'data-export-templates.quoting',
        defaultMessage: 'CSV quoting',
    },
    grid: {
        id: 'data-export-templates.grid',
        defaultMessage: 'Grid',
    },
    format: {
        id: 'data-export-templates.format',
        defaultMessage: 'Date format',
    },
    includeFiles: {
        id: 'data-export-templates.include.files',
        defaultMessage: 'Include files',
    },
    includeMetaData: {
        id: 'data-export-templates.include.metadata',
        defaultMessage: 'Include metadata',
    },
    activityDefault: {
        id: 'data-export-templates.activityDefault',
        defaultMessage: 'Activity Default',
    },
    createExportTemplate: {
        id: 'data-export-templates.createExportTemplate',
        defaultMessage: 'Create Export Template',
    },
    [ACTIVITY_TYPES.DRILLING]: {
        id: 'data-export-templates.drilling',
        defaultMessage: 'Drill Hole',
    },
    [ACTIVITY_TYPES.POINT]: {
        id: 'data-export-templates.point',
        defaultMessage: 'Point',
    },
    [ExportDestination.CSV]: {
        id: 'data-export-templates.csv',
        defaultMessage: 'CSV Files',
    },
    [ExportDestination.XLSX]: {
        id: 'data-export-templates.xlsx',
        defaultMessage: 'Excel File',
    },
    [ExportDestination.EVO_CSV]: {
        id: 'data-export-templates.evo_csv',
        defaultMessage: 'CSV Files to Seequent Evo',
    },
    [ExportDestination.EVO]: {
        id: 'data-export-templates.evo',
        defaultMessage: 'Publish to Seequent Evo',
    },
    [ExportEncoding.UTF8]: {
        id: 'data-export-templates.UTF8',
        defaultMessage: 'UTF-8',
    },
    [ExportEncoding.ANSI_LATIN1]: {
        id: 'data-export-templates.ANSI',
        defaultMessage: 'ANSI (Latin 1)',
    },
    [QuotesMode.QUOTE_ALL]: {
        id: 'data-export-templates.quote.all',
        defaultMessage: 'All values',
    },
    [QuotesMode.QUOTE_NONNUMERIC]: {
        id: 'data-export-templates.quote.minimal',
        defaultMessage: 'Non-numeric values only',
    },
    [QuotesMode.QUOTE_MINIMAL]: {
        id: 'data-export-templates.ANSI',
        defaultMessage: 'Values with special characters only',
    },
};

export const i18n = {
    selectedCountSelectedSelectAll: {
        id: 'data-export-templates-count-selected-select-all',
        defaultMessage: '{selectedCount} of {count} Export Templates Selected',
    },
    allItemsSelected: {
        id: 'all-data-export-templates-count-selected',
        defaultMessage: 'All {selectedCount} Export Templates Selected',
    },
    selectAllItems: {
        id: 'select-all-data-export-templates',
        defaultMessage: 'Select all {count} Export Templates',
    },
    clearAll: {
        id: 'data-export-templates-clear-all',
        defaultMessage: 'Clear All Export Templates',
    },
    paginationLabel: {
        id: 'data-export-templates-pagination-type-label',
        defaultMessage: 'Export Templates',
    },
    paginationTitle: {
        id: 'data-export-templates-pagination-title',
        defaultMessage: 'Show',
    },
    searchPlaceholder: {
        id: 'data-export-templates-search-placeholder',
        defaultMessage: 'Search',
    },
    emptyFilterTitle: {
        id: 'data-export-templates-empty-title',
        defaultMessage: 'No Export Templates Found.',
    },
    emptyFilterResults: {
        id: 'data-export-templates-empty-message',
        defaultMessage: 'There are no results.',
    },
    createNewTitle: {
        id: 'data-export-templates-create-new-title',
        defaultMessage: 'You Have No Export Templates',
    },
    createNewItem: {
        id: 'data-export-templates-create-new-message',
        defaultMessage: 'Export Templates will appear here as they are generated.',
    },
    include: {
        id: 'data-export-templates.sample-form-include',
        defaultMessage: 'Include:',
    },
    'select-all': {
        id: 'data-templates.select-all',
        defaultMessage: 'Select All',
    },
    'clear-all': {
        id: 'data-templates.clear-all',
        defaultMessage: 'Clear All',
    },
};
